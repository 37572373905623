import { Language } from 'src/app/shared/models/language.model';

export class User {
   public Name: string;
   public Language: Language;
   public Theme: string;
   public FontSize: number;
   public CanRateCompany: boolean;
   public CompanyRatedOn: Date;
   public LastAccessOn: Date;
   public WelcomePageLastSeenOn?: Date;
   public CanShowWelcomePage: boolean;

   constructor() {
      this.Theme = 'light-mode';
      this.LastAccessOn = new Date();
      this.CanRateCompany = true;
      this.Language = new Language();
      this.FontSize = 1;
      this.CanShowWelcomePage = true;
   }
}

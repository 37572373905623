import { NgModule } from "@angular/core";
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheet,
  MatBottomSheetModule,
} from "@angular/material/bottom-sheet";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

const MODULES = [
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSlideToggleModule,
  MatExpansionModule,
  MatBottomSheetModule,
  MatInputModule,
  MatFormFieldModule,
];

@NgModule({
  exports: [MODULES],
  providers: [
    { provide: MatBottomSheet },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
  ],
})
export class MaterialModule {}

import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheet,
} from "@angular/material/bottom-sheet";

@Component({
  selector: "modal-customer-name",
  templateUrl: "./customer-name.component.html",
})
export class CustomerNameComponent implements OnInit {
  public clientName!: string;

  constructor(
    private _bottomSheet: MatBottomSheet,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: {
      clientName: string;
    }
  ) {
    this.clientName = data.clientName;
  }

  ngOnInit(): void {}

  close() {
    this._bottomSheet.dismiss();
  }

  alterCustomerName() {
    this._bottomSheet.dismiss(this.clientName);
  }
}

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
   providedIn: 'root',
})
export class ThemeService {
   constructor(@Inject(DOCUMENT) private document: Document) {}

   setVariable(name, value) {
      this.document.documentElement.style.setProperty(name, value);
   }

   setDarkLightMode(primaryColor: string) {
      const $html = document.querySelector('html');
      $html.classList.toggle('dark-mode');

      if ($html.classList.value.includes('dark-mode')) {
         document.body.style.setProperty('--primary-color', '#303030');
         localStorage.setItem('pede-mais-garcom-theme', 'dark-mode');
      } else {
         document.body.style.setProperty('--primary-color', primaryColor || '#004a8f');
         localStorage.setItem('pede-mais-garcom-theme', 'light-mode');
      }
   }

   isDarkMode() {
      const theme = localStorage.getItem('pede-mais-garcom-theme');
      return theme === 'dark-mode';
   }
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
   enableProdMode();
}

const user = JSON.parse(localStorage.getItem('pede-mais-garcom-user'));

if (user && user.Theme === 'dark-mode') {
   const $html = document.querySelector('html');
   $html.classList.toggle('dark-mode');
}

platformBrowserDynamic()
   .bootstrapModule(AppModule)
   .catch((err) => console.error(err));

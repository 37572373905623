<ng-container *ngIf="openModal">
    <div class="modal multiple-dialog" @modal>
        <div class="modal-content">
            <div class="header-modal">
                <div class="title-modal">
                </div>
                <i class="fa-regular fa-xmark icon-close" (click)="close()"></i>
            </div>
            Digite a quantidade (kg)
            <input currencyMask type="text" [(ngModel)]="quantity" inputmode="numeric" placeholder="0,000"
                style="text-align: center;" autofocus>

            <button type="button" [disabled]="!quantity" (click)="handleSetProductQuantity()">Ok</button>
        </div>
    </div>
    <div (click)="close()" @overlay class="overlay multiple-dialog"></div>
</ng-container>
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Subscription } from "rxjs";
import { Order, OrderItem, OrderSubItem } from "../../models/order";
import { OrderCheckStorage } from "../../models/order-check.model";
import { EventService } from "../../services/event.service";
import { HubService } from "../../services/hub.service";
import { LoadingService } from "../../services/loading.service";
import { MenuService } from "../../services/menu-service.service";
import { NotificationService } from "../../services/notification.service";
import { OrderCheckService } from "../../services/order-check.service";
import { OrderService } from "../../services/order.service";
import { transitionAnimation } from "../../utils/animations";
import { getProductQuantity } from "../../utils/functions";
import { ProductDetailComponent } from "../product-detail/product-detail.component";

@Component({
  selector: "modal-order-cart",
  templateUrl: "./order-cart.component.html",
  styleUrls: ["./order-cart.component.scss"],
  animations: [transitionAnimation],
})
export class OrderCartComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public urlToBack: string;
  public openModal = false;
  public isSendingOrder = false;
  public order: Order;
  private orderTimeout: string | number | NodeJS.Timeout;
  public orderCheck = new OrderCheckStorage();

  @Input() customerName: string;
  @Output() afterOrderPlaced = new EventEmitter();
  @ViewChild(ProductDetailComponent, { static: true })
  productDetailComponent: ProductDetailComponent;

  @HostListener("window:popstate", ["$event"])
  onPopState(event: Event) {
    this.close();
  }

  constructor(
    private orderCheckService: OrderCheckService,
    private hubService: HubService,
    private notificationService: NotificationService,
    private loadingService: LoadingService,
    private eventService: EventService,
    private orderService: OrderService,
    private menuService: MenuService
  ) {}

  ngOnInit(): void {
    this.orderCheck = this.orderCheckService.getOrderCheckStorage();
    this.order = this.orderService.getOrder(this.orderCheck.CompanyId);

    this.subscriptions.push(
      this.eventService.orderChanged$.subscribe((order: Order) => {
        this.order = order;
      })
    );

    this.subscriptions.push(
      this.hubService.$menuServiceInternalError.subscribe(
        (description: string) => {
          if (this.openModal) {
            this.resetTimer();
            this.notificationService.showAlertMessage(
              "Houve um erro ao enviar o pedido"
            );
          }
        }
      )
    );

    this.subscriptions.push(
      this.orderCheckService.$orderCheckChanged.subscribe(
        (orderCheck: OrderCheckStorage) => {
          this.orderCheck = orderCheck;
        }
      )
    );

    this.subscriptions.push(
      this.hubService.$menuServiceOrderCreated.subscribe(() => {
        this.orderSended();
      })
    );

    this.subscriptions.push(
      this.hubService.$menuServiceDuplicateOrder.subscribe(() => {
        this.orderSended();
      })
    );

    this.subscriptions.push(
      this.hubService.$menuServiceProductNotFound.subscribe(
        (barcodes: string) => {
          this.resetTimer();
          const message = `Os seguintes cod. barras não estão cadastrados<br/><br/><b>${barcodes}<br/><br/></b>`;
          this.showInfoMessage(message);
        }
      )
    );

    this.subscriptions.push(
      this.hubService.$menuServiceOffline.subscribe(() => {
        this.resetTimer();
      })
    );

    this.subscriptions.push(
      this.hubService.$menuServiceInsufficientCredit.subscribe(() => {
        this.resetTimer();
        this.showInfoMessage("Saldo insuficiente.");
      })
    );
  }

  open(url: string) {
    this.openModal = true;

    this.urlToBack = url;
    history.pushState(
      null,
      "Pedemais Garçom | Carrinho",
      this.urlToBack.split("produtos").join("carrinho")
    );

    this.menuService.overflowService(false);

    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  close() {
    history.pushState(null, "Pedemais Garçom", this.urlToBack);
    this.menuService.overflowService(true);

    this.openModal = false;
  }

  editItem(orderItem: OrderItem, index: number) {
    this.productDetailComponent.openEdition(orderItem, index);
  }

  removeItem(orderItem: OrderItem) {
    this.orderService.removeProduct(orderItem);
  }

  getProductQuantity(
    subItems: OrderSubItem[],
    subItem: OrderSubItem,
    itemQuantity: number = 1
  ) {
    return getProductQuantity(subItems, subItem, itemQuantity);
  }

  sendOrderToMenuService() {
    this.orderCheck = this.orderCheckService.getOrderCheckStorage();
    this.sendOrder();
  }

  async sendOrder() {
    this.isSendingOrder = true;

    this.orderTimeout = setTimeout(async () => {
      this.showInfoMessage(
        "Ops...O pedido não pode ser enviado, tente novamente."
      );
      this.loadingService.hide();
    }, 15000);

    this.loadingService.show();
    const hubServiceOrder = this.orderService.getHubServiceOrder(
      this.orderCheck,
      this.customerName
    );

    this.hubService.createOrder(hubServiceOrder);
  }

  private orderSended() {
    this.resetTimer();
    this.notificationService.showSuccessMessage(
      "Pedido realizado com sucesso.",
      6
    );
    this.afterOrderPlaced.emit();
    this.close();
  }

  private resetTimer() {
    this.clearOrderTimeout();
    this.loadingService.hide();
    this.isSendingOrder = false;
  }

  private clearOrderTimeout() {
    if (this.orderTimeout) {
      clearTimeout(this.orderTimeout);
    }
  }

  private showInfoMessage(message) {
    if (this.openModal) {
      this.notificationService.showAlertMessage(message, 6);
    }
  }

  ngOnDestroy() {
    this.clearOrderTimeout();

    if (this.subscriptions) {
      this.subscriptions.forEach((s) => s.unsubscribe());
    }
  }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { Subscription } from 'rxjs';
import { MenuServiceParameterizationResponse } from '../../models/menu-service.model';
import { OrderCheckStorage } from '../../models/order-check.model';
import { HubService } from '../../services/hub.service';
import { LoadingService } from '../../services/loading.service';
import { NotificationService } from '../../services/notification.service';
import { OrderCheckService } from '../../services/order-check.service';
import { normalizeAccentedChars } from '../../utils/functions';

@Component({
   selector: 'modal-ordercheck-observation',
   templateUrl: './ordercheck-observation.component.html',
})
export class OrderCheckObservationComponent implements OnInit, OnDestroy {
   private subscriptions: Subscription[] = [];
   public openModal = false;
   public isSaving = false;
   public observation = '';
   public parameterization = new MenuServiceParameterizationResponse();
   public orderCheck = new OrderCheckStorage();

   constructor(
      private notificationService: NotificationService,
      private loadingService: LoadingService,
      private orderCheckService: OrderCheckService,
      private hubService: HubService,
      private _bottomSheet: MatBottomSheet,
      @Inject(MAT_BOTTOM_SHEET_DATA)
      public data: {
         parameterization: MenuServiceParameterizationResponse;
         orderCheck: OrderCheckStorage;
         openModal: boolean;
      }
   ) {
      this.parameterization = data.parameterization;
      this.orderCheck = data.orderCheck;
      this.observation = data.orderCheck.Observation;
      this.openModal = data.openModal;
   }

   ngOnInit(): void {
      this.isSaving = false;
      this.subscriptions.push(
         this.hubService.$menuServiceUpdateStatus.subscribe((message: string) => {
            if (this.openModal) {
               this.loadingService.hide();
               this.notificationService.showSuccessMessage(message || 'Observação alterada com sucesso');
               this.orderCheckService.setOrderCheckObservation(this.observation);
               this.close();
            }
         })
      );

      this.subscriptions.push(
         this.hubService.$menuServiceInternalError.subscribe(() => {
            this.loadingService.hide();
            this.showAlert('Houve um erro ao salvar a observação');
         })
      );

      this.subscriptions.push(
         this.hubService.$menuServiceClosedTable.subscribe((description: string) => {
            this.loadingService.hide();
            this.showAlert(description || 'Mesa fechada');
         })
      );

      this.subscriptions.push(
         this.hubService.$menuServiceInvalidTable.subscribe((description: string) => {
            this.loadingService.hide();
            this.showAlert(description || 'Houve um erro ao salvar a observação');
         })
      );

      this.subscriptions.push(
         this.hubService.$hubServiceOffline.subscribe((message: string) => {
            this.isSaving = false;
         })
      );
   }

   close() {
      this._bottomSheet.dismiss();
      this.openModal = false;
   }

   addObservation() {
      this.isSaving = true;
      if (this.observation !== this.orderCheck.Observation) {
         const table = this.orderCheckService.getHubServiceOrderCheckStatus(this.parameterization, this.orderCheck);
         table.Observation = normalizeAccentedChars(this.observation);
         this.hubService.updateOrderCheckStatus(table);
      } else {
         this.close();
      }
   }

   private showAlert(message: string) {
      if (this.openModal) {
         this.isSaving = false;
         this.notificationService.showAlertMessage(message);
      }
   }

   ngOnDestroy() {
      if (this.subscriptions) {
         this.subscriptions.forEach((s) => s.unsubscribe());
      }
   }
}

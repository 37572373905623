import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HubServiceOrderCheckHistory } from '../models/hub.model';
import { Order } from '../models/order';

@Injectable({
   providedIn: 'root',
})
export class EventService {
   private orderChange = new Subject();
   public orderChanged$ = this.orderChange.asObservable();

   private orderCheckHistoryChange = new BehaviorSubject(new HubServiceOrderCheckHistory());
   public orderCheckHistoryChanged$ = this.orderCheckHistoryChange.asObservable();

   publishOrderChange(order: Order) {
      this.orderChange.next(order);
   }

   publishOrderCheckHistoryChange(orderCheckHistory: HubServiceOrderCheckHistory) {
      this.orderCheckHistoryChange.next(orderCheckHistory);
   }
}

import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { Subscription } from 'rxjs';
import { MenuServiceParameterizationResponse } from '../../models/menu-service.model';
import { OrderCheckStorage } from '../../models/order-check.model';
import { HubService } from '../../services/hub.service';
import { LoadingService } from '../../services/loading.service';
import { NotificationService } from '../../services/notification.service';
import { OrderCheckService } from '../../services/order-check.service';
import { toInt } from '../../utils/functions';

@Component({
   selector: 'modal-ordercheck-group-head-count-input',
   templateUrl: './ordercheck-group-head-count-input.component.html',
   animations: [
      trigger('modal', [
         transition(':enter', [style({ top: -999 }), animate('500ms', style({ top: '50%' }))]),
         transition(':leave', [animate('500ms', style({ top: -999 }))]),
      ]),
   ],
})
export class OrdercheckGroupHeadCountInputComponent implements OnInit, OnDestroy {
   private subscriptions: Subscription[] = [];
   public openModal = false;
   public isSaving = false;
   public headCount: number;
   public parameterization = new MenuServiceParameterizationResponse();
   public orderCheck = new OrderCheckStorage();
   public type: string = 'mesa';

   constructor(
      private notificationService: NotificationService,
      private loadingService: LoadingService,
      private orderCheckService: OrderCheckService,
      private hubService: HubService,
      private _bottomSheet: MatBottomSheet,
      @Inject(MAT_BOTTOM_SHEET_DATA)
      public data: {
         parameterization: MenuServiceParameterizationResponse;
         orderCheck: OrderCheckStorage;
         openModal: boolean;
         type: string;
      }
   ) {
      this.parameterization = data.parameterization;
      this.orderCheck = data.orderCheck;
      this.headCount = data.orderCheck.HeadCount === 0 ? null : data.orderCheck.HeadCount;
      this.type = data.type;
      this.openModal = data.openModal;
   }

   ngOnInit(): void {
      this.subscriptions.push(
         this.hubService.$menuServiceUpdateStatus.subscribe((message: string) => {
            if (this.openModal) {
               this.loadingService.hide();
               this.notificationService.showSuccessMessage(message || 'Número de pessoas alterado com sucesso');
               this.orderCheckService.setOrderCheckGroupHeadCount(this.headCount);
               this.close();
            }
         })
      );

      this.subscriptions.push(
         this.hubService.$menuServiceInternalError.subscribe(() => {
            this.loadingService.hide();
            this.showAlert('Houve um erro ao salvar o número de pessoas');
         })
      );

      this.subscriptions.push(
         this.hubService.$menuServiceClosedTable.subscribe((description: string) => {
            this.loadingService.hide();
            this.showAlert(description || 'Mesa fechada');
         })
      );

      this.subscriptions.push(
         this.hubService.$menuServiceInvalidTable.subscribe((description: string) => {
            this.loadingService.hide();
            this.showAlert(description || 'Houve um erro o número de pessoas');
         })
      );

      this.subscriptions.push(
         this.hubService.$hubServiceOffline.subscribe((message: string) => {
            this.isSaving = false;
         })
      );
   }

   close() {
      this._bottomSheet.dismiss();
      this.openModal = false;
   }

   alterHeadCount() {
      this.isSaving = true;
      this.headCount = toInt(this.headCount.toString());
      if (this.headCount !== this.orderCheck.HeadCount) {
         const table = this.orderCheckService.getHubServiceOrderCheckStatus(this.parameterization, this.orderCheck);
         table.HeadCount = this.headCount;
         this.hubService.updateOrderCheckStatus(table);
      } else {
         this.close();
      }
   }

   private showAlert(message: string) {
      if (this.openModal) {
         this.notificationService.showAlertMessage(message);
         this.isSaving = false;
      }
   }

   ngOnDestroy() {
      if (this.subscriptions) {
         this.subscriptions.forEach((s) => s.unsubscribe());
      }
   }
}

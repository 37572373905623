<ng-container *ngIf="openModal">
    <div class="modal modal-nav multiple-dialog" @modal>
        <div class="modal-content">
            <div class="header-modal">
                <i class="fa-solid fa-arrow-left" (click)="close()"></i>
                <div class="title-modal"> </div>
            </div>

            <div *ngIf="operatorLogin" class="user">
                <i class="fa-duotone fa-circle-user"></i>
                {{operatorLogin}}
            </div>

            <div class="item-menu" (click)="handleSelectOption('changeOrderCheck')">
                <i class="fa-duotone fa-arrow-right-arrow-left"></i>
                Alterar comanda/mesa
            </div>
            <div *ngIf="!isGroupCheck" class="item-menu" (click)="handleSelectOption('addCustomerName')">
                <i class="fa-duotone fa-user"></i>
                Informar nome do cliente
            </div>
            <div class="item-menu" (click)="handleSelectOption('addObservation')">
                <i class="fa-duotone fa-money-check-pen"></i>
                Observação da {{isGroupCheck ? 'Mesa' : 'Comanda'}}
            </div>
            <div *ngIf="!isGroupCheck" class="item-menu" (click)="handleSelectOption('bindTable')">
                <i class="fa-duotone fa-link"></i>
                Vincular uma mesa
            </div>


            <div class="settings-content">
                <div class="group-settings">
                    <div class="title-section">
                        Tema
                    </div>
                    <mat-slide-toggle [(ngModel)]="isDarkMode" (click)="handleDarkLightMode(isDarkMode)">
                        Tema Escuro
                    </mat-slide-toggle>
                </div>

                <hr />

                <div class="group-settings">
                    <div class="title-section">
                        Tamanho da Fonte
                    </div>
                    <div class="group-buttons">
                        <button (click)="setFontSize('a-')" [disabled]="fontSize === 89.75">A-</button>
                        <button (click)="setFontSize('a')">A</button>
                        <button (click)="setFontSize('a+')" [disabled]="fontSize === 121.75">A+</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div (click)="close()" @overlay class="overlay multiple-dialog"></div>
</ng-container>
import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheet,
} from "@angular/material/bottom-sheet";
import { MenuServiceProduct } from "../../models/menu-service.model";

@Component({
  selector: "modal-price-value",
  templateUrl: "./price-value.component.html",
})
export class PriceValueComponent implements OnInit {
  public priceValue!: number;
  public product: MenuServiceProduct;

  constructor(
    private _bottomSheet: MatBottomSheet,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: {
      product: MenuServiceProduct;
    }
  ) {
    this.product = data.product;
  }

  ngOnInit(): void {}

  close() {
    this._bottomSheet.dismiss();
  }

  setPriceValue() {
    this.product.SellValue = this.priceValue;
    this._bottomSheet.dismiss(this.product);
  }
}

export class OrderCheckStorage {
   public OperatorId?: string;
   public OperatorLogin?: string;
   public CompanyId?: number;
   public HeadCount?: number;
   public IsGroupCheck?: boolean;
   public OrderCheckCode?: number;
   public Loggedon?: string;
   public Observation?: string;
   public BindedTableNumber?: number;
}

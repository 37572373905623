<div class="header-bottom-sheet">
    <i class="fa-light fa-xmark" (click)="close()"></i>
</div>
<div class="bottom-sheet-content">
    <mat-form-field class="example-full-width">
        <mat-label>Nº da mesa</mat-label>
        <input appOnlyNumber matInput type="text" maxlength="5" [(ngModel)]="bindedTableNumber" inputmode="numeric"
            placeholder="00" autofocus>
    </mat-form-field>
    <button type="button" [disabled]="bindedTableNumber <= 0 || orderCheck.BindedTableNumber == bindedTableNumber"
        (click)="handleBindTable()">Ok</button>
</div>
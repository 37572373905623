import {
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Subscription } from "rxjs";
import { HubServiceOrderCheckHistory } from "../../models/hub.model";
import { MenuServiceParameterizationResponse } from "../../models/menu-service.model";
import { OrderItem, OrderSubItem } from "../../models/order";
import { OrderCheckStorage } from "../../models/order-check.model";
import { EventService } from "../../services/event.service";
import { HubService } from "../../services/hub.service";
import { LoadingService } from "../../services/loading.service";
import { MenuService } from "../../services/menu-service.service";
import { NotificationService } from "../../services/notification.service";
import { OrderCheckService } from "../../services/order-check.service";
import { transitionAnimation } from "../../utils/animations";
import { getProductQuantity } from "../../utils/functions";

@Component({
  selector: "modal-ordercheck-history",
  templateUrl: "./ordercheck-history.component.html",
  styleUrls: ["./ordercheck-history.component.scss"],
  animations: [transitionAnimation],
})
export class OrderCheckHistoryComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public openModal = false;
  public canCustomerCloseTable = false;
  public orderItems: OrderItem[] = [];
  public orderCheckHistory: HubServiceOrderCheckHistory;
  public parameterization = new MenuServiceParameterizationResponse();
  public orderCheck = new OrderCheckStorage();
  public urlToBack: string;

  @Output() afterBillIssued = new EventEmitter();

  @HostListener("window:popstate", ["$event"])
  onPopState(event: Event) {
    this.close();
  }

  constructor(
    private notificationService: NotificationService,
    private loadingService: LoadingService,
    private eventService: EventService,
    private orderCheckService: OrderCheckService,
    private hubService: HubService,
    private menuService: MenuService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.hubService.$menuServiceBillIssued.subscribe(() => {
        this.loadingService.hide();
        this.notificationService.showSuccessMessage(
          "Conta emitida com sucesso"
        );
        this.afterBillIssued.emit();
        this.close();
      })
    );

    this.subscriptions.push(
      this.hubService.$menuServiceInvalidPrinter.subscribe(() => {
        this.loadingService.hide();
        this.showAlert("Impressora padrão não configurada !");
      })
    );

    this.subscriptions.push(
      this.hubService.$menuServiceInternalError.subscribe(() => {
        this.loadingService.hide();
        this.showAlert("Houve um erro ao fechar a conta");
      })
    );

    this.subscriptions.push(
      this.hubService.$menuServiceClosedTable.subscribe((description) => {
        this.loadingService.hide();
        this.showAlert(description || "Mesa fechada");
      })
    );

    this.subscriptions.push(
      this.hubService.$menuServiceInvalidTable.subscribe((description) => {
        this.loadingService.hide();
        this.showAlert(description || "Houve um erro ao fechar a conta");
      })
    );

    this.subscriptions.push(
      this.eventService.orderCheckHistoryChanged$.subscribe(
        (orderCheckHistory: HubServiceOrderCheckHistory) => {
          this.orderCheckHistory = orderCheckHistory;
          this.orderCheckHistoryChanged();
        }
      )
    );
  }

  orderCheckHistoryChanged() {
    const orderCheckItems = this.orderCheckHistory?.items
      ? this.orderCheckHistory.items.filter((n) => !n.isCancelled)
      : [];
    this.orderItems = orderCheckItems.map((item) => {
      const hasComplements =
        item.subItems &&
        item.subItems.length > 0 &&
        item.subItems[0].productId === item.productId;
      const isCombo =
        !hasComplements && item.subItems && item.subItems.length > 0;

      return {
        ApplyServiceCharge: item.ApplyServiceCharge,
        ClientName: item.clientName
          ? item.clientName
          : this.setClientName(item),
        CompleteObservation: item.observation,
        CompositionMembers: [],
        GrossValue: item.totalValue,
        HasComplements: hasComplements,
        IsSplitOnSale: item.IsSplitOnSale,
        Observation: item.observation,
        OrderCheckItemTime: item.orderCheckItemTime
          ? item.orderCheckItemTime
          : this.setOrderCheckItemTime(item),
        OriginalProductName: item.productName,
        OriginalSellValue: 0,
        ProductBarcode: item.productBarcode,
        ProductId: item.productId,
        ProductName: item.productName,
        Quantity: item.quantity,
        QuantityToShow: item.quantity,
        SellerLogin: item.sellerLogin,
        SellValue: this.onCalcSellValue(
          item.quantity,
          item.sellValue,
          item.subItems,
          isCombo
        ),
        /*SellValue: isCombo
          ? this.teste(item.subItems)
          : item.sellValue * (isCombo ? item.quantity : 1),*/
        ServesHowManyPeople: item.ServesHowManyPeople,
        SubItems: item.subItems
          ? item.subItems
              .filter((n) => n.productId !== item.productId)
              .map((subitem) => {
                return {
                  ProductId: subitem.productId,
                  ProductName: subitem.productName,
                  OriginalProductName: subitem.productName,
                  Quantity: subitem.quantity / item.quantity,
                  SellValue: subitem.sellValue,
                  OriginalSellValue: subitem.sellValue,
                  GrossValue: subitem.totalValue,
                  ProductBarcode: subitem.productBarcode,
                  TotalValue: subitem.totalValue / item.quantity,
                  Observation: subitem.observation,
                  ApplyServiceCharge: subitem.ApplyServiceCharge,
                  IsSplitOnSale: subitem.IsSplitOnSale,
                };
              })
          : [],
        TotalValue: item.totalValue,
      };
    });
  }

  onCalcSellValue(
    quantity: number,
    sellValue: number,
    subItems: any[],
    isCombo: boolean
  ): number {
    let value: number = 0;

    if (isCombo) {
      subItems.forEach((subItem: any) => {
        value += subItem.totalValue;
      });
    } else {
      value = sellValue * quantity;
    }

    return value;
  }

  setClientName(items): string {
    if (items && items.subItems) {
      return (items.clientName = items.subItems[0].clientName);
    }
  }

  setOrderCheckItemTime(items): any {
    if (items && items.subItems) {
      return items.subItems[0].orderCheckItemTime;
    }
  }

  open(
    parameterization: MenuServiceParameterizationResponse,
    orderCheck: OrderCheckStorage,
    url: string
  ) {
    this.parameterization = parameterization;
    this.orderCheck = orderCheck;
    this.openModal = true;

    this.urlToBack = url;
    history.pushState(
      null,
      "Pedemais Garçom | Resumo",
      this.urlToBack.split("produtos").join("resumo")
    );

    this.menuService.overflowService(false);

    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  close() {
    history.pushState(null, "Pedemais Garçom", this.urlToBack);
    this.menuService.overflowService(true);
    this.openModal = false;
  }

  closeTab() {
    this.notificationService
      .showConfirmBox(
        null,
        "Tem certeza que deseja pedir a conta agora?",
        "warning"
      )
      .then((ret) => {
        if (ret) {
          this.loadingService.show();
          const table = this.orderCheckService.getHubServiceTable(
            this.parameterization,
            this.orderCheck
          );
          this.hubService.closeTab(table);
        }
      });
  }

  private showAlert(message) {
    if (this.openModal) {
      this.notificationService.showAlertMessage(message);
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((s) => s.unsubscribe());
    }
  }

  getProductQuantity(
    subItems: OrderSubItem[],
    subItem: OrderSubItem,
    itemQuantity: number = 1
  ) {
    return getProductQuantity(subItems, subItem, itemQuantity);
  }
}

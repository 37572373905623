<div class="header-bottom-sheet">
    <i class="fa-light fa-xmark" (click)="close()"></i>
</div>
<div class="bottom-sheet-content">
    <mat-form-field class="example-full-width">
        <mat-label>Como seu cliente gostaria de ser chamado?</mat-label>
        <input matInput type="text" maxlength="30" [(ngModel)]="clientName" placeholder="Digite aqui..." autofocus>
    </mat-form-field>
    <button type="button" [disabled]="!clientName || clientName.trim().length === 0"
        (click)="alterCustomerName()">Ok</button>
</div>
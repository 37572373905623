<ng-container *ngIf="openModal">
  <div class="modal modal-detail-products" @modal>
    <div class="img-product-zoom" *ngIf="orderItem.ImageUrl">
      <div class="image-zoom">
        <img loading="lazy" src="{{ orderItem.ImageUrl }}" alt="{{ orderItem.ProductName }}" />
        <i class="fa-regular fa-xmark btn-close-image-zoom" (click)="handleImageZoom(false)"></i>
      </div>
    </div>

    <div class="product-content">
      <div class="header-modal">
        <i class="fa-solid fa-arrow-left" (click)="close()"></i>
        <div class="title-modal">Detalhes do item</div>
      </div>

      <div class="group-detail-product">
        <div class="img-product" *ngIf="orderItem.ImageUrl" (click)="handleImageZoom(true)">
          <img loading="lazy" src="{{ orderItem.ImageUrl }}" alt="{{ orderItem.ProductName }}" />
        </div>

        <div class="detail-product">
          <div *ngIf="orderItem.IsProductsAgeRestricted" class="warning-18">
            <i class="fa-duotone fa-triangle-exclamation"></i>
            Produto para maiores de 18 anos
          </div>

          <div *ngIf="orderItem.ProductIsCompositionMember" class="all-you-can-eat">Incluso no rodízio</div>

          <div class="group-product-name">
            <div class="product-name">
              {{ orderItem.ProductName }}
            </div>

            <div class="product-server-many" *ngIf="orderItem.ServesHowManyPeople > 0">
              <i class="fa-duotone fa-users"></i>
              {{ orderItem.ServesHowManyPeople }} {{ orderItem.ServesHowManyPeople > 1 ? "pessoas" : "pessoa" }}
            </div>
          </div>
          <div class="product-additional-inf">
            {{ orderItem.AdditionalInfo }}
          </div>
          <div *ngIf="!orderItem.ProductIsCompositionMember" class="product-sell-value">
            {{ orderItem.ProductTypeName === "Combo" ? "A partir de " : "" }}
            {{ (orderItem.ProductTypeName === "Combo" ? orderItem.ProductStepMinValue : orderItem.SellValue) | money }}
          </div>
        </div>

        <!--Combo------------------>
        <div *ngIf="orderItem.ProductSteps && orderItem.ProductSteps.length > 0">
          <section *ngFor="let step of orderItem.ProductSteps">
            <div class="title-section" [id]="'step-index-' + step.Position">
              <div class="left">
                <span>{{ step.Name }}</span>
                <p *ngIf="!isViewMode">{{ step.Quantity }} de {{ step.MaxQuantity }}</p>
              </div>
              <div class="rigth">
                <div *ngIf="step.MinQuantity > 0 && step.Quantity !== step.MaxQuantity && !isViewMode" class="required">
                  Obrigatório
                </div>
                <i class="fa-duotone fa-check-double checked" *ngIf="step.Quantity === step.MaxQuantity"></i>
              </div>
            </div>

            <div class="group-add-items" *ngIf="step.MaxQuantity > 1; else productRadio">
              <div class="items-to-add" *ngFor="let item of step.StepItems">
                <div class="list-item">
                  {{ item.ProductName }}
                  <span>{{ item.SellPrice | money }}</span>
                </div>
                <div class="btn-more-minus" *ngIf="!isViewMode">
                  <button type="button" [disabled]="item.QuantityToShow === 0" class="btn-minus"
                    (click)="decrementStepProduct(step, item)">
                    -
                  </button>
                  {{ item.QuantityToShow }}
                  <button type="button" class="btn-more" [disabled]="
                      item.QuantityToShow === step.MaxQuantity ||
                      step.Quantity === step.MaxQuantity ||
                      step.ProductStepSelectedItemsCount >= step.MaxQuantity ||
                      (!step.CanRepeatSameItem && item.QuantityToShow > 0)
                    " (click)="incrementStepProduct(step, item)">
                    +
                  </button>
                </div>
              </div>
            </div>
            <ng-template #productRadio>
              <mat-radio-group class="radio-group" *ngIf="!isViewMode">
                <mat-radio-button class="radio-button" *ngFor="let item of step.StepItems" [value]="item"
                  [checked]="item.QuantityToShow > 0" (click)="checkStepProduct($event, step, item)">
                  <div class="list-item">
                    {{ item.ProductName }}
                    <span>{{ item.SellPrice | money }}</span>
                  </div>
                </mat-radio-button>
              </mat-radio-group>

              <div class="radio-group" *ngIf="isViewMode">
                <div class="radio-button" *ngFor="let item of step.StepItems">
                  <div class="list-item">
                    {{ item.ProductName }}
                    <span>{{ item.SellPrice | money }}</span>
                  </div>
                </div>
              </div>
            </ng-template>
          </section>
        </div>

        <section *ngIf="!isViewMode">
          <div class="title-section">Alguma observação?</div>
          <mat-form-field style="margin-top: 15px;">
            <mat-label>Alguma observação?</mat-label>
            <textarea matInput name="textArea" id="orderItemObservation" placeholder="Adicione uma observação..."
              [(ngModel)]="orderItem.Observation" rows="4"></textarea>
          </mat-form-field>
        </section>
      </div>
    </div>
    <div class="group-buttons-footer" *ngIf="!isViewMode">
      <div class="btn-more-minus" *ngIf="!orderItem.GetWeight">
        <button type="button" class="btn-minus" (click)="decrementProduct()">-</button>
        {{ orderItem.Quantity }}
        <button type="button" class="btn-more" (click)="incrementProduct()">+</button>
      </div>
      <button type="button" class="btn-add"
        (click)="!orderItem.GetWeight ? saveProduct() : openModalProductQuantity(orderItem)"
        [disabled]="!stepItemsRequiredValuesComplete">
        <i class="fa-duotone fa-cart-shopping" style="margin-right: 0.625rem"></i>
        <span *ngIf="!isEdit">Adicionar</span>
        <span *ngIf="isEdit">Atualizar</span>
        <span> {{ orderItem.TotalValue | money }}</span>
      </button>
    </div>
  </div>
  <div (click)="close()" @overlay class="overlay"></div>
</ng-container>

<modal-product-quantity (onsetProductQuantity)="setProductQuantity($event)"></modal-product-quantity>
<ng-container *ngIf="openModal">
    <div class="modal modal-order-history" @modal>
        <div class="resume-content">
            <div class="header-modal">
                <i class="fa-solid fa-arrow-left" (click)="close()"></i>
                <div class="title-modal"> Resumo da Conta </div>
            </div>

            <div class="order-check-number">
                {{ (orderCheck?.IsGroupCheck ? 'Mesa |' : 'Comanda |' ) }}
                <span> {{orderCheck.OrderCheckCode}}</span>
            </div>

            <div class="group-items-resume">
                <div class="item-order" *ngFor="let item of orderItems">
                    <div class="group-name-sell-value">
                        <div class="name-item">
                            {{item.Quantity}} x {{item.ProductName}}
                        </div>
                        <div class="sell-value">
                            {{item.SellValue | money}}
                        </div>
                    </div>
                    <div class="complement-item" *ngFor="let complement of item.SubItems">
                        {{ item.HasComplements ? '+' : '&bull;' }}
                        {{getProductQuantity(item.SubItems, complement, item.Quantity)}} x
                        {{complement.ProductName}}

                        <div class="sell-value" *ngIf="item.HasComplements && complement.SellValue > 0">
                            {{complement.TotalValue * item.Quantity | money}}
                        </div>
                    </div>
                    <div class="observation-item" *ngIf="item.Observation">
                        **{{item.Observation}}
                    </div>
                    <div class="made-by">
                        Lançado {{item.SellerLogin ? 'pelo Garçom ' + item.SellerLogin : item.ClientName &&
                        item.ClientName.length >
                        0 ? 'por ' + item.ClientName : 'pelo Cliente' }} às
                        {{item.OrderCheckItemTime | date:'dd/MM/yyyy HH:mm' }}
                    </div>
                </div>
            </div>

            <div class="total-order">
                Total: {{orderCheckHistory.totalValue | money}}
            </div>


        </div>
        <div class="group-buttons-footer">
            <button type="button" class="btn-add" (click)="closeTab()"
                [disabled]="!this.orderItems || this.orderItems.length === 0">Emitir Conta</button>
        </div>
    </div>
    <div (click)="close()" @overlay class="overlay"></div>
</ng-container>
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { HubServiceOrderCheckStatus, HubServiceTable } from '../models/hub.model';
import { MenuServiceParameterizationResponse } from '../models/menu-service.model';
import { OrderCheckStorage } from '../models/order-check.model';
import { normalizeAccentedChars } from '../utils/functions';
import { LocalStorageService } from './local-storage.service';

@Injectable({
   providedIn: 'root',
})
export class OrderCheckService {
   private orderCheckChange = new Subject();
   public $orderCheckChanged = this.orderCheckChange.asObservable();

   constructor(private localStorageService: LocalStorageService) {}

   getOrderCheckStorage(): OrderCheckStorage {
      const orderCheckStorage = this.localStorageService.getOrderCheckStorage();
      return orderCheckStorage;
   }

   setOrderCheckInfo(orderCheck: OrderCheckStorage) {
      const { CompanyId, OrderCheckCode, IsGroupCheck } = orderCheck;
      const orderCheckStorage = this.localStorageService.getOrderCheckStorage();

      if (orderCheckStorage) {
         orderCheckStorage.CompanyId = CompanyId;
         orderCheckStorage.OrderCheckCode = OrderCheckCode;
         orderCheckStorage.IsGroupCheck = IsGroupCheck;
         this.localStorageService.setOrderCheckStorage(orderCheckStorage);
         this.orderCheckChange.next(orderCheckStorage);
      }
   }

   removeOrderCheckInfo() {
      const orderCheckStorage = this.localStorageService.getOrderCheckStorage();

      if (orderCheckStorage) {
         orderCheckStorage.HeadCount = null;
         orderCheckStorage.IsGroupCheck = false;
         orderCheckStorage.OrderCheckCode = null;
         orderCheckStorage.BindedTableNumber = null;

         this.localStorageService.setOrderCheckStorage(orderCheckStorage);
         this.orderCheckChange.next(orderCheckStorage);
      }
   }

   setOrderCheckCollaborator(orderCheck: OrderCheckStorage) {
      const loggedon = moment().toISOString();
      orderCheck.Loggedon = loggedon;
      this.localStorageService.setOrderCheckStorage(orderCheck);
      this.orderCheckChange.next(orderCheck);
   }

   setOrderCheckGroupHeadCount(headCount: number) {
      const orderCheckStorage = this.localStorageService.getOrderCheckStorage();

      if (orderCheckStorage) {
         orderCheckStorage.HeadCount = headCount;
         this.localStorageService.setOrderCheckStorage(orderCheckStorage);
         this.orderCheckChange.next(orderCheckStorage);
      }
   }

   setOrderCheckObservation(observation: string) {
      const orderCheckStorage = this.localStorageService.getOrderCheckStorage();

      if (orderCheckStorage) {
         orderCheckStorage.Observation = observation;
         this.localStorageService.setOrderCheckStorage(orderCheckStorage);
         this.orderCheckChange.next(orderCheckStorage);
      }
   }

   setOrderCheckBindedTableNumber(bindedTableNumber: number) {
      const orderCheckStorage = this.localStorageService.getOrderCheckStorage();

      if (orderCheckStorage) {
         orderCheckStorage.BindedTableNumber = bindedTableNumber;
         this.localStorageService.setOrderCheckStorage(orderCheckStorage);
         this.orderCheckChange.next(orderCheckStorage);
      }
   }

   getParameterization(): MenuServiceParameterizationResponse {
      return this.localStorageService.getParameterization();
   }

   setParameterization(parameterization: MenuServiceParameterizationResponse) {
      this.localStorageService.setParameterization(parameterization);
   }

   getHubServiceTable(parameterization: MenuServiceParameterizationResponse, orderCheck: OrderCheckStorage): HubServiceTable {
      const table: HubServiceTable = {
         CompanyId: parameterization.CompanyId,
         IsGroupCheck: orderCheck.IsGroupCheck,
         OrderCheckCode: orderCheck.OrderCheckCode,
         TableNumber: orderCheck.OrderCheckCode,
         SellerLogin: orderCheck.OperatorLogin,
         ServiceCharges: parameterization.ServiceCharges
            ? parameterization.ServiceCharges.map((value) => {
                 return {
                    Value: value,
                    CalculatedTotalValue: 0,
                    CalculatedValue: 0,
                 };
              })
            : [],
      };

      return table;
   }

   getHubServiceOrderCheckStatus(
      parameterization: MenuServiceParameterizationResponse,
      orderCheck: OrderCheckStorage
   ): HubServiceOrderCheckStatus {
      const table: HubServiceOrderCheckStatus = {
         CompanyId: parameterization.CompanyId,
         OrderCheckCode: orderCheck.OrderCheckCode,
         TableNumber: orderCheck.BindedTableNumber,
         // TableNumber: orderCheck.IsGroupCheck ? orderCheck.OrderCheckCode : orderCheck.BindedTableNumber,
         HeadCount: orderCheck.HeadCount,
         Observation: normalizeAccentedChars(orderCheck.Observation),
         Status: 1,
         IsBlocked: false,
      };

      return table;
   }

   getTableType(isGroupCheck: boolean) {
      const type = isGroupCheck ? 'Mesa' : 'Comanda';
      return type;
   }

   isLoginSessionExpired(orderCheckStorage: OrderCheckStorage): boolean {
      try {
         const hours = moment().diff(moment(orderCheckStorage.Loggedon), 'hours');
         return hours > 8;
      } catch (error) {
         return true;
      }
   }
}

import { Injectable } from '@angular/core';
import { MenuServiceParameterizationResponse } from '../models/menu-service.model';
import { Order } from '../models/order';
import { OrderCheckStorage } from '../models/order-check.model';
import { User } from '../models/user.model';
import { toJson } from '../utils/functions';

@Injectable({
   providedIn: 'root',
})
export class LocalStorageService {
   private _orderCheckStorageName = 'pede-mais-garcom-order-check';
   private _parameterizationName = 'pede-mais-garcom-parameterization';
   private _orderStorageName = 'pede-mais-garcom-order';
   private _userStorageName = 'pede-mais-garcom-user';
   private _hubStorageName = 'pede-mais-garcom-hub';

   constructor() {}

   private getItem(key: string, fromSessionStorage: boolean = false): any {
      const str = fromSessionStorage ? window.sessionStorage.getItem(key) : window.localStorage.getItem(key);
      return toJson(str);
   }

   getOrderCheckStorage(): OrderCheckStorage {
      const orderCheckStorage = this.getItem(this._orderCheckStorageName, true);
      return orderCheckStorage || new OrderCheckStorage();
   }

   setOrderCheckStorage(orderCheckStorage: OrderCheckStorage) {
      window.sessionStorage.setItem(this._orderCheckStorageName, JSON.stringify(orderCheckStorage));
   }

   removeOrderCheckStorage() {
      window.sessionStorage.removeItem(this._orderCheckStorageName);
   }

   getParameterization(): MenuServiceParameterizationResponse {
      const parameterization = window.localStorage.getItem(this._parameterizationName);
      return parameterization ? JSON.parse(parameterization) : null;
   }

   setParameterization(parameterization: MenuServiceParameterizationResponse) {
      if (parameterization) {
         window.localStorage.setItem(this._parameterizationName, JSON.stringify(parameterization));
      }
   }

   getMenuServiceOrder(): Order {
      const order = JSON.parse(window.sessionStorage.getItem(this._orderStorageName)) as Order;
      return order;
   }

   setMenuServiceOrder(order: Order) {
      try {
         window.sessionStorage.setItem(this._orderStorageName, JSON.stringify(order));
      } catch (error) {}
   }

   removeMenuServiceOrder() {
      window.sessionStorage.removeItem(this._orderStorageName);
   }

   setHubConnectionId(connectionId: string) {
      const info = { ConnectedOn: new Date(), ConnectionId: connectionId };
      window.sessionStorage.setItem(this._hubStorageName, JSON.stringify(info));
   }

   saveUserInfo(user: User) {
      if (user) {
         user.LastAccessOn = new Date();
         window.localStorage.setItem(this._userStorageName, JSON.stringify(user));
      }
   }

   getUserInfo(): User {
      const user = window.localStorage.getItem(this._userStorageName);
      return user ? JSON.parse(user) : new User();
   }

   getConfiguration(): MenuServiceParameterizationResponse {
      const parameterization = window.localStorage.getItem(this._parameterizationName);
      const param = parameterization ? JSON.parse(parameterization) : new MenuServiceParameterizationResponse();

      const user = this.getUserInfo();
      if (user.Theme !== 'dark-mode') {
         document.body.style.setProperty('--primary-color', param.PrimaryColor);
      }

      return param;
   }
}

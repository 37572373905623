import { MenuServiceCharge } from "./menu-service.model";

export class HubServiceOrderCheckHistory {
   public companyId: number;
   public orderCheckCode: string;
   public isGroupCheck: boolean;
   public tableNumber: number;
   public limitValue: number;
   public couvertValue: number;
   public consumptionValue: number;
   public totalValue: number;
   public timeOfStay: string;
   public orderCheckOpeningDate: string;
   public lastItemTime?: string;
   public limitReleased: boolean;
   public isBlocked: boolean;
   public headCount: number;
   public observation: string;
   public customerName: string;
   public status: number;
   public items: OrderCheckItem[];

  constructor() {}
}

export class OrderCheckItem {
  public productId: string;
  public productTypeName: string;
  public productBarcode: string;
  public productName: string;
  public quantity: number;
  public sellValue: number;
  public additionValue: number;
  public discountValue: number;
  public totalValue: number;
  public observation: string;
  public isCancelled: boolean;
  public stepIndex?: number;
  public isFractioned: boolean;
  public ApplyServiceCharge: boolean;
  public IsSplitOnSale: boolean;
  public sellerLogin?: string;
  public orderCheckItemTime?: string;
  public clientName?: string;
  public subItems: OrderCheckItem[];
  public productIsCompositionMember: boolean;
  public productCompositionMembers: OrderCheckProductCompositionMember[];
  public ServesHowManyPeople?: number;
}

export class OrderCheckProductCompositionMember {
  public productId: string;
  public productBarcode: string;
  public maxQuantity?: number;
}

export class HubServiceOrder {
  public CompanyId: number;
  public OrderControlId: string;
  public TableNumber: number;
  public OrderCheckCode: number;
  public IsGroupCheck: boolean;
  public CardId: string;
  public CustomerName: string;
  SellerLogin?: string;
  public Items: HubServiceOrderItem[];
}

export class HubServiceOrderItem {
  public StepIndex?: number;
  public SellerCode?: string;
  public SellerLogin?: string;
  public ProductId: string;
  public ProductTypeName: string;
  public MarketingClassificationId: string;
  public MarketingClassificationName: string;
  public ProductBarcode: string;
  public ProductName: string;
  public Quantity: number;
  public SellValue: number;
  public AdditionValue: number;
  public DiscountValue: number;
  public TotalValue: number;
  public Observation: string;
  public IsFractioned: boolean;
  public ProductIsCompositionMember: boolean;
  public ApplyServiceCharge: boolean;
  public IsSplitOnSale: boolean;
  public SubItems?: HubServiceOrderItem[];
  public Printers: HubServiceOrderItemPrinter[];
  public ProductCompositionMembers: HubServiceOrderItemCompositionMember[];

  constructor() {
    this.IsFractioned = false;
    this.ProductIsCompositionMember = false;
  }
}

export class HubServiceOrderItemPrinter {
  public Id: string;
  public PrinterId: string;
  public PrinterName: string;
  public PrinterTypeId?: string;
  public PrinterTypeName: string;
  public PrinterModelId?: string;
  public PrinterModelName?: string;
  public IpAddress: string;
  public Port?: number;
  public PortSpeed?: number;
  public InitCommand: string;
  public WindowsPrinterName: string;
  public MonitorCode?: number;
  public PrinterModelIsPanel: boolean;
  public OrderOriginId?: string;
  public OrderOriginName: string;
}

export class HubServiceOrderItemCompositionMember {
  public ProductId: string;
  public ProductBarcode: string;
  public MaxQuantity?: number;
}

export class HubServiceTable {
  CompanyId: number;
  TableNumber: number;
  OrderCheckCode: number;
  IsGroupCheck: boolean;
  SellerLogin?: string;
  ServiceCharges?: MenuServiceCharge[];
}

export class HubServiceOrderCheckStatus {
  CompanyId: number;
  OrderCheckCode: number;
  Status: number;
  IsBlocked: boolean;
  HeadCount: number;
  Observation: string;
  TableNumber: number;
}

export class HubServiceNotification {
  name: string;
  description: string;
}

import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MenuServiceProduct, MenuServiceProductNote } from '../../models/menu-service.model';
import { MenuService } from '../../services/menu-service.service';
import { copyObject } from '../../utils/functions';

@Component({
   selector: 'modal-product-observation',
   templateUrl: './product-observation.component.html',
   styleUrls: ['./product-observation.component.scss'],
   animations: [
      trigger('modal', [
         transition(':enter', [style({ top: -999 }), animate('500ms', style({ top: '50%' }))]),
         transition(':leave', [animate('500ms', style({ top: -999 }))]),
      ]),
   ],
})
export class ProductObservationComponent implements OnInit {
   public openModal = false;
   public product: MenuServiceProduct;
   public productNotes: MenuServiceProductNote[];

   public requiredNotesFilled = false;
   public hasAnyProductNoteSelected = false;
   public observation = '';
   public selectedOptionName = '';
   public combinedObservation = '';

   public defaultOptions = [{ Name: 'Com' }, { Name: 'Sem' }, { Name: 'Pouco' }, { Name: 'Muito' }];

   constructor(private menuService: MenuService) {}

   ngOnInit(): void {}

   open(product: MenuServiceProduct) {
      this.selectedOptionName = 'Com';
      this.product = product;
      this.observation = product.Observation;
      this.productNotes = copyObject(product.ProductNotes);
      this.checkRequiredNotesFilled();
      this.openModal = true;

      this.menuService.overflowService(false);

      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
   }

   close() {
      this.menuService.overflowService(true);
      this.openModal = false;
   }

   saveObservation() {
      this.product.ProductNotes = this.productNotes;
      this.product.Observation = this.observation;
      this.close();
   }

   selectOption(name: string) {
      this.selectedOptionName = name;
   }

   setOption(name: string, required: boolean = false) {
      this.productNotes
         .filter((n) => n.Name === name)
         .forEach((ret) => {
            if (ret.SelectedOption === this.selectedOptionName) {
               ret.SelectedOption = '';

               if (required) {
                  const element = document.getElementsByClassName(`btn-${name}`)[0];
                  if (element) element.classList.add('required');
               }
            } else {
               ret.SelectedOption = this.selectedOptionName;

               if (required) {
                  const element = document.getElementsByClassName(`btn-${name}`)[0];
                  if (element) element.classList.remove('required');
               }
            }
         });
      this.checkRequiredNotesFilled();
   }

   checkRequiredNotesFilled() {
      if (this.productNotes && this.productNotes.length > 0) {
         const requireds = this.productNotes?.some(
            (n) => (!n.SelectedOption || n.SelectedOption.length === 0) && n.IsRequired
         );
         this.requiredNotesFilled = requireds;

         this.hasAnyProductNoteSelected = this.productNotes?.some(
            (n) => !n.SelectedOption || n.SelectedOption.length === 0
         );
      } else {
         this.requiredNotesFilled = false;
         this.hasAnyProductNoteSelected = false;
      }
   }
}

<div class="header-bottom-sheet">
   <i class="fa-light fa-xmark" (click)="close()"></i>
</div>
<div class="bottom-sheet-content">
   <mat-form-field class="example-full-width">
      <mat-label>Nº de pessoas na {{type}}</mat-label>
      <input appOnlyNumber matInput type="text" maxlength="2" [(ngModel)]="headCount" inputmode="numeric"
         placeholder="00" autofocus>
   </mat-form-field>
   <button type="button" [disabled]="isSaving||!headCount || headCount < 1" (click)="alterHeadCount()">Ok</button>
</div>
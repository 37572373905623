<div class="header-bottom-sheet">
    <i class="fa-light fa-xmark" (click)="close()"></i>
</div>
<div class="bottom-sheet-content">
    <mat-form-field style="margin-top: 15px;">
        <mat-label>Observação da {{orderCheck.IsGroupCheck ? 'mesa' : 'comanda' }}</mat-label>
        <textarea matInput name="textArea" id="orderItemObservation" placeholder="Adicione uma observação..."
            [(ngModel)]="observation" rows="4"></textarea>
    </mat-form-field>
    <button type="button" [disabled]=" isSaving || !observation || observation.trim().length === 0"
        (click)="addObservation()">Ok</button>
</div>
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';

@Injectable({
   providedIn: 'root',
})
export class CryptoService {
   private cryptoKey = 'NcVcqQIHLdXsPpUW';

   constructor() {}

   decryptFromAes(text: string): string {
      const key = CryptoJS.enc.Utf8.parse(this.cryptoKey);
      const genIv = CryptoJS.enc.Utf8.parse(this.cryptoKey);
      return CryptoJS.enc.Utf8.stringify(
         CryptoJS.AES.decrypt(text, key, { keySize: 128 / 8, iv: genIv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
      );
   }

   encryptToAes(text: string): string {
      const key = CryptoJS.enc.Utf8.parse(this.cryptoKey);
      const genIv = CryptoJS.enc.Utf8.parse(this.cryptoKey);
      return CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key, {
         keySize: 128 / 8,
         iv: genIv,
         mode: CryptoJS.mode.CBC,
         padding: CryptoJS.pad.Pkcs7,
      }).toString();
   }

   encodeToBase64(text: string) {
      return Base64.stringify(Utf8.parse(text));
   }

   hashToHmacSHA256(text: string): string {
      return CryptoJS.HmacSHA256(text, this.cryptoKey).toString();
   }

   decodeBase64(encoded: string) {
      const encodedWord = CryptoJS.enc.Base64.parse(encoded);
      const decoded = CryptoJS.enc.Utf8.stringify(encodedWord);

      return decoded;
   }
}

import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { User } from "../../models/user.model";
import { MenuService } from "../../services/menu-service.service";
import { UserService } from "../../services/user.service";
import { transitionAnimation } from "../../utils/animations";

@Component({
  selector: "modal-ordercheck-options",
  templateUrl: "./ordercheck-options.component.html",
  styleUrls: ["./ordercheck-options.component.scss"],
  animations: [transitionAnimation],
})
export class OrderCheckOptionsComponent implements OnInit {
  public openModal = false;
  public isGroupCheck = false;
  public fontSize = 97.75;
  public isDarkMode = false;
  public userInfo: User;

  @Input() operatorLogin = "";
  @Output() afterOptionSelected = new EventEmitter<string>();

  @HostListener("window:popstate", ["$event"])
  onPopState(event: Event) {
    this.close();
  }

  constructor(
    private userService: UserService,
    private menuService: MenuService
  ) {}

  ngOnInit(): void {}

  open(isGroupCheck: boolean) {
    this.isGroupCheck = isGroupCheck;

    this.userInfo = this.userService.getUser();
    this.openModal = true;

    this.menuService.overflowService(false);

    if (this.userInfo.Theme === "dark-mode") {
      this.isDarkMode = true;
    } else {
      this.isDarkMode = false;
    }
  }

  close() {
    this.menuService.overflowService(true);
    this.openModal = false;
  }

  handleSelectOption(option: string) {
    this.afterOptionSelected.emit(option);
    this.close();
  }

  handleDarkLightMode(theme: boolean) {
    let setTheme: string;
    if (theme) {
      setTheme = "light-mode";
    } else {
      setTheme = "dark-mode";
    }

    this.userInfo = this.userService.setTheme(this.userInfo, setTheme);
  }

  setFontSize(idd: string) {
    if (idd === "a+") {
      if (this.fontSize < 121.75) {
        this.fontSize += 1;
      }
    } else if (idd === "a-") {
      if (this.fontSize > 89.75) {
        this.fontSize -= 1;
      }
    } else {
      this.fontSize = 97.75;
    }

    const htmlRoot: HTMLElement = document.getElementsByTagName(
      "html"
    )[0] as HTMLElement;
    if (htmlRoot != null) {
      htmlRoot.style.fontSize = `${this.fontSize}%`;
      this.userService.setFontSize(this.fontSize);
    }
  }
}

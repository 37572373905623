import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
   HubServiceNotification,
   HubServiceOrder,
   HubServiceOrderCheckHistory,
   HubServiceOrderCheckStatus,
   HubServiceTable,
} from '../models/hub.model';
import { getMenuPassword } from '../utils/functions';
import { CryptoService } from './crypto.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({ providedIn: 'root' })
export class HubService {
   private connection: signalR.HubConnection;
   private failedHubConnectionAttempts = 0;

   // private _connectionStatusChange = new BehaviorSubject(false);
   // public $connectionStatusChanged = this._connectionStatusChange.asObservable();

   private _getOrderCheck = new Subject();
   public $orderCheckGetted = this._getOrderCheck.asObservable();

   private _closeTab = new Subject();
   public $tabClosed = this._closeTab.asObservable();

   private _hubServiceOffline = new Subject();
   public $hubServiceOffline = this._hubServiceOffline.asObservable();

   private _menuServiceOffline = new Subject();
   public $menuServiceOffline = this._menuServiceOffline.asObservable();

   private _menuServiceLogin = new Subject();
   public $menuServiceLoged = this._menuServiceLogin.asObservable();

   private _menuServiceTabOnPayingState = new Subject();
   public $menuServiceTabOnPayingState = this._menuServiceTabOnPayingState.asObservable();

   private _menuServiceOrderCreated = new Subject();
   public $menuServiceOrderCreated = this._menuServiceOrderCreated.asObservable();

   private _menuServiceProductNotFound = new Subject();
   public $menuServiceProductNotFound = this._menuServiceProductNotFound.asObservable();

   private _menuServiceInternalError = new Subject();
   public $menuServiceInternalError = this._menuServiceInternalError.asObservable();

   private _menuServiceBillIssued = new Subject();
   public $menuServiceBillIssued = this._menuServiceBillIssued.asObservable();

   private _menuServiceInvalidPrinter = new Subject();
   public $menuServiceInvalidPrinter = this._menuServiceInvalidPrinter.asObservable();

   private _menuServiceOrderWithoutAuxiliaryItem = new Subject();
   public $menuServiceOrderWithoutAuxiliaryItem = this._menuServiceOrderWithoutAuxiliaryItem.asObservable();

   private _menuServiceProductCompositionNotFound = new Subject();
   public $menuServiceProductCompositionNotFound = this._menuServiceProductCompositionNotFound.asObservable();

   private _menuServiceForbiddenTable = new Subject();
   public $menuServiceForbiddenTable = this._menuServiceForbiddenTable.asObservable();

   private _menuServiceInsufficientCredit = new Subject();
   public $menuServiceInsufficientCredit = this._menuServiceInsufficientCredit.asObservable();

   private _menuServiceConnectionError = new Subject();
   public $menuServiceConnectionError = this._menuServiceConnectionError.asObservable();

   private _menuServiceDuplicateOrder = new Subject();
   public $menuServiceDuplicateOrder = this._menuServiceDuplicateOrder.asObservable();

   private _menuServiceBindTableInvalidTable = new Subject();
   public $menuServiceBindTableInvalidTable = this._menuServiceBindTableInvalidTable.asObservable();

   private _menuServiceBindTableTableAlreadyHasItems = new Subject();
   public $menuServiceBindTableTableAlreadyHasItems = this._menuServiceBindTableTableAlreadyHasItems.asObservable();

   private _menuServiceBindTableInternalError = new Subject();
   public $menuServiceBindTableInternalError = this._menuServiceBindTableInternalError.asObservable();

   private _menuServiceInvalidTable = new Subject();
   public $menuServiceInvalidTable = this._menuServiceInvalidTable.asObservable();

   private _menuServiceClosedTable = new Subject();
   public $menuServiceClosedTable = this._menuServiceClosedTable.asObservable();

   private _menuServiceUpdateStatus = new Subject();
   public $menuServiceUpdateStatus = this._menuServiceUpdateStatus.asObservable();

   private _menuServiceTableInPayment = new Subject();
   public $menuServiceTableInPayment = this._menuServiceTableInPayment.asObservable();

   private _menuServiceBlockedTable = new Subject();
   public $menuServiceBlockedTable = this._menuServiceBlockedTable.asObservable();

   private _menuServiceLinkedOrders = new Subject();
   public $menuServiceLinkedOrders = this._menuServiceLinkedOrders.asObservable();

   private _menuServiceOrderWithoutItems = new Subject();
   public $menuServiceOrderWithoutItems = this._menuServiceOrderWithoutItems.asObservable();

   constructor(private cryptoService: CryptoService, private localStorageService: LocalStorageService) {
      this.initServerConnection();
   }

   private initServerConnection(): void {
      const logLevel = environment.production ? signalR.LogLevel.Information : signalR.LogLevel.Debug;
      this.connection = new signalR.HubConnectionBuilder().withUrl(`${environment.hubUrl}`).configureLogging(logLevel).build();

      this.connection.serverTimeoutInMilliseconds = 60000;
      this.connection.keepAliveIntervalInMilliseconds = 30000;
      this.registerCallbacks();

      this.startConnection();
   }

   private startConnection() {
      this.connection
         ?.start()
         .then(() => {
            this.failedHubConnectionAttempts = 0;
         })
         .catch(() => {
            this.failedHubConnectionAttempts = +1;
            if (this.failedHubConnectionAttempts < 5) {
               setTimeout(() => {
                  this.startConnection();
               }, 5000);
            }
         });
   }

   private callServerHub(method: string, params: any, nopassword: boolean = false) {
      this.sendDataToServer(method, params, nopassword);
   }

   private sendDataToServer(method: string, params: any, nopassword: boolean = false, attempts: number = 1) {
      if (nopassword) {
         this.connection
            .send(method, params)
            .then(() => {})
            .catch(() => {
               this.retrySendDataToServer(method, params, nopassword, attempts);
            });
      } else {
         const password = this.cryptoService.encryptToAes(getMenuPassword());
         this.connection
            .send(method, params, password)
            .then(() => {})
            .catch(() => {
               this.retrySendDataToServer(method, params, nopassword, attempts);
            });
      }
   }

   private retrySendDataToServer(method: string, params: any, nopassword: boolean = false, attempts: number = 1) {
      if (attempts < 5) {
         attempts += 1;
         setTimeout(() => {
            this.sendDataToServer(method, params, nopassword, attempts);
         }, 1500);
      } else {
         this._hubServiceOffline.next('Não foi possível estabelecer conexão com o servidor, tente novamente');
      }
   }

   checkAuthentication(table: HubServiceTable): void {
      this.callServerHub('CheckAuthentication', table);
   }

   createOrder(order: HubServiceOrder): void {
      this.callServerHub('CreateOrder', order);
   }

   getOrderCheck(table: HubServiceTable) {
      this.callServerHub('GetOrderCheck', table);
   }

   closeTab(table: HubServiceTable) {
      this.callServerHub('CloseTab', table);
   }

   updateOrderCheckStatus(orderCheckStatus: HubServiceOrderCheckStatus) {
      this.callServerHub('UpdateOrderCheckStatus', orderCheckStatus, true);
   }

   private registerCallbacks() {
      this.connection.onclose(() => {
         this.startConnection();
      });

      this.connection.on('GetConnected', (connectionId: string) => {
         this.localStorageService.setHubConnectionId(connectionId);
      });

      this.connection.on('GetOrderCheck', (orderCheck: HubServiceOrderCheckHistory) => {
         this._getOrderCheck.next(orderCheck);
      });

      this.connection.on('CloseTab', (orderCheck) => {
         this._closeTab.next(orderCheck);
      });

      this.connection.on('GetNotification', (notification: HubServiceNotification) => {
         const notificationsAllowed = {
            MenuServiceClientOffline: () => this._menuServiceOffline.next(),
            MenuServiceTabOnPayingState: () => this._menuServiceTabOnPayingState.next(),
            MenuServiceOrderCreated: () => this._menuServiceOrderCreated.next(),
            MenuServiceProductNotFound: () => this._menuServiceProductNotFound.next(notification.description),
            MenuServiceInternalError: () => this._menuServiceInternalError.next(),
            MenuServiceBillIssued: () => this._menuServiceBillIssued.next(),
            MenuServiceInvalidPrinter: () => this._menuServiceInvalidPrinter.next(),
            MenuServiceOrderWithoutAuxiliaryItem: () => this._menuServiceOrderWithoutAuxiliaryItem.next(),
            MenuServiceProductCompositionNotFound: () => this._menuServiceProductCompositionNotFound.next(),
            MenuServiceForbiddenTable: () => this._menuServiceForbiddenTable.next(),
            MenuServiceInsufficientCredit: () => this._menuServiceInsufficientCredit.next(),
            MenuServiceConnectionError: () => this._menuServiceConnectionError.next(notification?.description),
            MenuServiceDuplicateOrder: () => this._menuServiceDuplicateOrder.next(),

            MenuServiceBindTableInvalidTable: () => this._menuServiceBindTableInvalidTable.next(),
            MenuServiceBindTableTableAlreadyHasItems: () => this._menuServiceBindTableTableAlreadyHasItems.next(),
            MenuServiceBindTableInternalError: () => this._menuServiceBindTableInternalError.next(),

            MenuServiceInvalidTable: () => {
               this._menuServiceInvalidTable.next(notification.description);
            },

            MenuServiceClosedTable: () => {
               this._menuServiceClosedTable.next(notification.description);
            },

            MenuServiceUpdateStatus: () => {
               this._menuServiceUpdateStatus.next(notification.description);
            },

            MenuServiceTableInPayment: () => {
               this._menuServiceTableInPayment.next(notification.description);
            },

            MenuServiceBlockedTable: () => {
               this._menuServiceBlockedTable.next(notification.description);
            },

            MenuServiceLinkedOrders: () => {
               this._menuServiceLinkedOrders.next(notification.description);
            },

            MenuServiceOrderWithoutItems: () => {
               this._menuServiceOrderWithoutItems.next(notification.description);
            },
         };

         const notificationMethod = notificationsAllowed[notification.name];

         if (notificationMethod) {
            notificationMethod();
         } else {
         }
      });
   }
}

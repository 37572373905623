<ng-container *ngIf="openModal">
    <div class="modal modal-observation" @modal>
        <div class="modal-content">
            <div class="header-modal">
                <i class="fa-solid fa-arrow-left" (click)="close()"></i>
            </div>
            <div class="group-observation-product">
                <div class="group-options" *ngIf="productNotes && productNotes.length > 0">
                    <button type="button" class="btn-option"
                        [ngClass]="{'actived' : selectedOptionName === option.Name}"
                        *ngFor="let option of defaultOptions" (click)="selectOption(option.Name)">
                        {{option.Name}}
                    </button>
                </div>
                <div class="group-list-options" *ngIf="productNotes && productNotes.length > 0">
                    <button type="button" class="btn-list btn-{{note.Name}}" *ngFor="let note of productNotes"
                        (click)="setOption(note.Name, note.IsRequired)" [ngClass]="{'required' : note.IsRequired}">
                        {{note.Name}}
                        <span *ngIf="note.SelectedOption" class="observation">{{note.SelectedOption}}</span>
                    </button>
                </div>
                <mat-form-field style="margin-top: 15px;">
                    <mat-label>Outras observações</mat-label>
                    <textarea matInput name="textArea" id="orderItemObservation"
                        placeholder="Adicione uma observação..." [(ngModel)]="observation" rows="4"></textarea>
                </mat-form-field>

                <button type="button" [disabled]="requiredNotesFilled && (hasAnyProductNoteSelected || observation)"
                    (click)="saveObservation()">Ok</button>
            </div>
        </div>
    </div>
</ng-container>
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheet,
} from "@angular/material/bottom-sheet";
import { Subscription } from "rxjs";
import { MenuServiceParameterizationResponse } from "../../models/menu-service.model";
import { OrderCheckStorage } from "../../models/order-check.model";
import { HubService } from "../../services/hub.service";
import { LoadingService } from "../../services/loading.service";
import { NotificationService } from "../../services/notification.service";
import { OrderCheckService } from "../../services/order-check.service";
import { toInt } from "../../utils/functions";

@Component({
  selector: "modal-bind-table",
  templateUrl: "./bind-table.component.html",
})
export class BindTableComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public openModal = false;
  public bindedTableNumber: number;
  public parameterization = new MenuServiceParameterizationResponse();
  public orderCheck = new OrderCheckStorage();

  constructor(
    private notificationService: NotificationService,
    private loadingService: LoadingService,
    private orderCheckService: OrderCheckService,
    private hubService: HubService,
    private _bottomSheet: MatBottomSheet,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: {
      parameterization: MenuServiceParameterizationResponse;
      orderCheck: OrderCheckStorage;
      openModal: boolean;
    }
  ) {
    this.parameterization = data.parameterization;
    this.orderCheck = data.orderCheck;
    this.bindedTableNumber =
      data.orderCheck.BindedTableNumber === 0
        ? null
        : data.orderCheck.BindedTableNumber;
    this.openModal = data.openModal;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.hubService.$menuServiceUpdateStatus.subscribe((message: string) => {
        if (this.openModal) {
          this.loadingService.hide();
          this.notificationService.showSuccessMessage(
            message || "Mesa alterada com sucesso"
          );
          this.orderCheckService.setOrderCheckBindedTableNumber(
            this.bindedTableNumber
          );
          this.close();
        }
      })
    );

    this.subscriptions.push(
      this.hubService.$menuServiceInternalError.subscribe(() => {
        this.loadingService.hide();
        this.showAlert("Houve um erro ao salvar o número da mesa");
      })
    );

    this.subscriptions.push(
      this.hubService.$menuServiceClosedTable.subscribe(
        (description: string) => {
          this.loadingService.hide();
          this.showAlert(description || "Mesa fechada");
        }
      )
    );

    this.subscriptions.push(
      this.hubService.$menuServiceInvalidTable.subscribe(
        (description: string) => {
          this.loadingService.hide();
          this.showAlert(description || "Houve um erro o número da mesa");
        }
      )
    );

    this.subscriptions.push(
      this.hubService.$menuServiceBindTableTableAlreadyHasItems.subscribe(
        (description: string) => {
          this.loadingService.hide();
          this.showAlert(
            description ||
              "Não é possível vincular uma comanda a mesa que já tenha itens"
          );
        }
      )
    );
  }

  close() {
    this._bottomSheet.dismiss();
    this.openModal = false;
  }

  handleBindTable() {
    this.bindedTableNumber = toInt(this.bindedTableNumber?.toString());
    if (this.bindedTableNumber !== this.orderCheck.BindedTableNumber) {
      const table = this.orderCheckService.getHubServiceOrderCheckStatus(
        this.parameterization,
        this.orderCheck
      );
      table.TableNumber = this.bindedTableNumber;
      this.hubService.updateOrderCheckStatus(table);
    }
  }

  private showAlert(message: string) {
    if (this.openModal) {
      this.notificationService.showAlertMessage(message);
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((s) => s.unsubscribe());
    }
  }
}

import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
   selector: 'modal-product-quantity',
   templateUrl: './product-quantity.component.html',
   animations: [
      trigger('overlay', [
         transition(':enter', [style({ opacity: 0 }), animate('500ms', style({ opacity: 0.5 }))]),
         transition(':leave', [animate('500ms', style({ opacity: 0 }))]),
      ]),
      trigger('modal', [
         transition(':enter', [style({ top: -999 }), animate('500ms', style({ top: '50%' }))]),
         transition(':leave', [animate('500ms', style({ top: -999 }))]),
      ]),
   ],
})
export class ProductQuantityComponent implements OnInit {
   public openModal = false;
   public quantity: number;
   public orderItem: any;

   @Output() onsetProductQuantity = new EventEmitter();

   constructor() {}

   ngOnInit(): void {}

   open(orderItem: any) {
      this.quantity = orderItem.Quantity === 0 ? null : orderItem.Quantity === 1 ? null : orderItem.Quantity;
      this.orderItem = orderItem;
      this.openModal = true;
   }

   close() {
      this.openModal = false;
   }

   handleSetProductQuantity() {
      this.orderItem.Quantity = this.quantity;
      this.orderItem.TotalValue = parseFloat((this.orderItem.SellValue * this.orderItem.Quantity).toFixed(3));

      this.onsetProductQuantity.emit(this.orderItem);

      this.close();
   }
}

<ng-container *ngIf="openModal">
    <div class="modal modal-order-history" @modal>
        <div class="resume-content">
            <div class="header-modal">
                <i class="fa-solid fa-arrow-left" (click)="close()"></i>
                <div class="title-modal"> Itens do pedido </div>
            </div>

            <div class="group-items-resume" *ngIf="order.Items && order.Items.length > 0">
                <div class="item-order" *ngFor="let item of order?.Items; index as i">
                    <div class="item" (click)="editItem(item, i)">
                        <div class="group-name-sell-value">
                            <div class="name-item">
                                {{item.Quantity}} x {{item.ProductName}}
                            </div>
                            <div class="sell-value">
                                {{( item.ProductTypeName.toLowerCase() === 'combo' ?
                                item.TotalValue :
                                item.SellValue * item.Quantity
                                ) | money}}
                            </div>
                        </div>
                        <div class="complement-item" *ngFor="let subItem of item.SubItems">
                            {{ item.HasComplements ? '+' : '&bull;' }}
                            {{getProductQuantity(item.SubItems, subItem, item.Quantity)}} x
                            {{subItem.ProductName}}

                            <!-- Precisa alinhar a esquerda -->
                            <div class="sell-value" *ngIf="item.HasComplements && subItem.SellValue > 0">
                                {{subItem.TotalValue * item.Quantity | money}}
                            </div>
                        </div>
                        <div class="observation-item" *ngIf="item.CompleteObservation">
                            **{{item.CompleteObservation}}
                        </div>
                    </div>
                    <i class="fa-solid fa-trash icon-delete" (click)="removeItem(item)"></i>
                </div>
            </div>

            <div class="group-items-resume no-items" *ngIf="order.Items && order.Items.length === 0">
                <i class="fa-duotone fa-cart-xmark"></i>
                Ainda não há produtos aqui
            </div>

            <div class="total-order" *ngIf="order.Items && order.Items.length > 0">
                Total: {{order.TotalValue | money}}
            </div>


        </div>
        <div class="group-buttons-footer">
            <button type="button" class="btn-add" (click)="sendOrderToMenuService()"
                [disabled]="!order.ItemsQuantity || order.ItemsQuantity === 0">
                Enviar pedido
            </button>
        </div>
    </div>
    <div (click)="close()" @overlay class="overlay"></div>
</ng-container>

<modal-product-detail></modal-product-detail>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MoneyPipe } from './pipes/money.pipe';
import { OnlyNumberDirective } from './directives/only-number.directive';

@NgModule({
   declarations: [MoneyPipe, OnlyNumberDirective],
   imports: [CommonModule],
   exports: [MoneyPipe, OnlyNumberDirective],
   providers: [MoneyPipe, OnlyNumberDirective],
})
export class SharedModule {}

import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';

@Injectable()
export class NotificationService {
   private defaultSettings = {
      timeOut: 2000,
      // positionClass: 'toast-bottom-center',
      positionClass: 'toast-top-center',
      // positionClass: 'toast-center-center',
      preventDuplicates: false,
      progressBar: true,
      tapToDismiss: true,
      enableHtml: true,
   };

   constructor(private toastr: ToastrService) {}

   showSuccessMessage(content?: string, timeout?: number, title?: string): number {
      this.setTimeOut(timeout);
      if (!content) {
         content = 'OperationSuccessful';
      }
      const notification = this.toastr.success(content, title, this.defaultSettings);
      return notification ? notification.toastId : null;
   }

   showInfoMessage(content?: string, timeout?: number, title?: string): number {
      this.setTimeOut(timeout);
      const notification = this.toastr.info(content, title, this.defaultSettings);
      return notification ? notification.toastId : null;
   }

   showAlertMessage(content?: string, timeout?: number, title?: string): number {
      this.setTimeOut(timeout);
      if (!content) {
         content = 'Processing';
      }
      const notification = this.toastr.warning(content, title, this.defaultSettings);
      return notification ? notification.toastId : null;
   }

   clearNotification(notificationId: number) {
      this.toastr.clear(notificationId);
   }

   private setTimeOut(timeout?) {
      if (timeout !== undefined && timeout != null) {
         this.defaultSettings.timeOut = timeout * 1000;
      } else {
         this.defaultSettings.timeOut = 2000;
      }
   }

   async showConfirmBox(
      title: string,
      text: string,
      typeAction,
      confirmText: string = 'Sim',
      cancelText: string = 'Não'
   ): Promise<boolean> {
      const result = await swal({
         title,
         text,
         type: typeAction,
         showCancelButton: true,
         confirmButtonColor: '#4267b2',
         cancelButtonColor: '#d33',
         reverseButtons: true,
         confirmButtonText: confirmText,
         cancelButtonText: cancelText,
         html: text,
      });

      if (result.value) {
         return true;
      } else {
         return false;
      }
   }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import {
  CURRENCY_MASK_CONFIG,
  CurrencyMaskConfig,
  CurrencyMaskModule,
} from "ng2-currency-mask";
import { MaterialModule } from "../material/material.module";
import { SharedModule } from "../shared/shared.module";
import { BindTableComponent } from "./components/bind-table/bind-table.component";
import { CustomerNameComponent } from "./components/customer-name/customer-name.component";
import { LoadingComponent } from "./components/loading/loading.component";
import { OrderCartComponent } from "./components/order-cart/order-cart.component";
import { OrdercheckGroupHeadCountInputComponent } from "./components/ordercheck-group-head-count-input/ordercheck-group-head-count-input.component";
import { OrderCheckHistoryComponent } from "./components/ordercheck-history/ordercheck-history.component";
import { OrderCheckObservationComponent } from "./components/ordercheck-observation/ordercheck-observation.component";
import { OrderCheckOptionsComponent } from "./components/ordercheck-options/ordercheck-options.component";
import { PriceValueComponent } from "./components/price-value/price-value.component";
import { ProductDetailComponent } from "./components/product-detail/product-detail.component";
import { ProductObservationComponent } from "./components/product-observation/product-observation.component";
import { ProductQuantityComponent } from "./components/product-quantity/product-quantity.component";
import { NotificationService } from "./services/notification.service";

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  decimal: ",",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: ".",
};

const CORE_COMPONENTS = [
  LoadingComponent,
  OrdercheckGroupHeadCountInputComponent,
  OrderCheckHistoryComponent,
  OrderCheckOptionsComponent,
  OrderCheckObservationComponent,
  BindTableComponent,
  ProductDetailComponent,
  OrderCartComponent,
  ProductObservationComponent,
  CustomerNameComponent,
  ProductQuantityComponent,
  PriceValueComponent,
];
const MODULES = [MaterialModule, RouterModule, SharedModule];

@NgModule({
  declarations: [CORE_COMPONENTS],
  imports: [CommonModule, FormsModule, MODULES, CurrencyMaskModule],
  exports: [CORE_COMPONENTS],
  providers: [
    NotificationService,
    {
      provide: CURRENCY_MASK_CONFIG,
      useValue: CustomCurrencyMaskConfig,
    },
  ],
})
export class CoreModule {}

import * as pako from 'pako';
import { OrderSubItem } from '../models/order';

const toFloat = (value: number, decimalPlaces: number = 3) => parseFloat(value.toFixed(decimalPlaces));
const toInt = (value: string) => parseInt(value, 10);

const copyObject = (obj: any): any => {
   return obj ? JSON.parse(JSON.stringify(obj)) : null;
};
const isDecimal = (quantity: number) => parseFloat(quantity.toString()) !== toInt(quantity.toString());

const getProductQuantity = (subItems: OrderSubItem[], subItem: OrderSubItem, itemQuantity: number = 1) => {
   //const decimalItemsQuantity = subItems.filter((sub: OrderSubItem): boolean => isDecimal(sub.Quantity) && sub.StepIndex === subItem.StepIndex).length;
   const decimalItemsQuantity = subItem.Quantity * itemQuantity;
   return decimalItemsQuantity.toFixed(2).toString().replace('.', ',');
   //return isDecimal(subItem.Quantity) ? `1/${decimalItemsQuantity}` : subItem.Quantity * itemQuantity;
};

const convertToFraction = (value: number): string => {
   if (value % 1 !== 0) {
      const divider = Math.round(1 / value);
      return `1/${divider}`;
   } else {
      return value.toString();
   }
};

const addDays = (date: Date, days: number): Date => {
   date.setDate(date.getDate() + days);
   return date;
};

const addHours = (date: Date, hours: number): Date => {
   date.setHours(date.getDate() + hours);
   return date;
};

const decrementFractionatedProductQuantity = (quantity: number): number => {
   if (quantity <= 1) {
      let divider = Math.round(1 / quantity);
      divider += divider === 8 ? 0 : 1;
      quantity = toFloat(1 / divider);
   } else {
      quantity--;
   }
   return quantity;
};

const incrementFractionatedProductQuantity = (quantity: number): number => {
   if (quantity < 1) {
      let divider = Math.round(1 / quantity);
      divider -= 1;
      quantity = toFloat(1 / divider);
   } else {
      quantity++;
   }
   return quantity;
};

const formatProductName = (name: string, barCode: string) => `${name} (${barCode})`;

const patterOnlyNumbers = () => {
   return new RegExp(/[0-9,]/);
};

const toJson = (obj: any): any => {
   if (obj) {
      try {
         return JSON.parse(obj);
      } catch (error) {
         return null;
      }
   } else {
      return null;
   }
};

const encodeString = (object) => {
   const encodedString = pako.deflate(object, { gzip: true, to: 'string' });
   const data = btoa(encodedString);

   return data;
};

const getMenuPassword = () => 'pedemaisgarcom';

const normalizeAccentedChars = (s: string) => {
   return s ? s.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : s;
};

export {
   addDays,
   addHours,
   convertToFraction,
   copyObject,
   decrementFractionatedProductQuantity,
   encodeString,
   formatProductName,
   getMenuPassword,
   getProductQuantity,
   incrementFractionatedProductQuantity,
   isDecimal,
   normalizeAccentedChars,
   patterOnlyNumbers,
   toFloat,
   toInt,
   toJson,
};
